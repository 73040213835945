.apply__filters {
  font-size: 17px;
  background-image: linear-gradient(
    to top,
    rgba(168, 40, 74),
    rgb(218, 120, 75)
  );
  color: $white;
  padding: 0px 11px;
  margin-top: 27px;
  border: none;
  height: 38px;
  margin-left: 5px;
  border-radius: 50% !important;
}
.row {
  flex-direction: row !important;
  margin-left: 7px;
}
.filters {
  flex-direction: column !important;
}
.no-borders {
  border: none !important;
  box-shadow: none !important;
  padding: 0;
}
.no-borders::after {
  border: none !important;
  box-shadow: none !important;
  padding: 0;
}
.box-shadow {
  box-shadow: inset 0 0 0.3em 0 rgb(253, 0, 118), 0 0 0.3em 0 rgb(253, 0, 118);
}
.refund-dropdown:hover {
  background-color: none;
  border-color: none;
  box-shadow: none;
}
.refund-dropdown {
  padding: 10px;
  border-radius: 20px;
  color: white;
  border-color: #bb6944;
}
.refund-flex1 {
  flex-wrap: wrap;
  justify-content: space-between;
}
.refund-flex {
  flex-wrap: wrap;
  justify-content: space-between;
}
.refund-error-div {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 700px) {
  .refund-error-text-desktop {
    display: none;
  }
  .refund-error-div {
    flex-direction: column !important;
  }
}
@media screen and (min-width: 700px) {
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
  }
}

.btn-outline-primary:hover {
  background-color: transparent !important;
  border-color: #bb6944 !important;
}
.refund-filters {
  display: flex;
  flex-direction: row;
}
.space-between {
  justify-content: space-between;
}
.download-refund {
  border-radius: 20px !important;
}
.full-width {
  width: 100% !important;
}
.circle-status-filter {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: conic-gradient(#ffab00 50%, green 50%);
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
  transform: rotate(45deg);
  cursor: pointer;
}
.refund-error-text {
  color: red;
  margin-top: 29px;
  margin-left: 15px;
  clear: both;
}

.pending-status {
  background: conic-gradient(#ffab00 50%, #ffab00 50%) !important;
}
.collected-status {
  background: conic-gradient(green 50%, green 50%) !important;
}
