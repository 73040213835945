// @import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
// @media screen and (max-width: 700px) {
//   .container__drop {
//     width: 100% !important;
//   }
// }
// .container__drop {
//   height: 50vh;
//   width: 30vw;
//   margin: 0 0;
//   // padding: 20px;
//   // box-shadow: 0 0.5rem 2rem 1rem rgba(0, 0, 0, 0.1);
//   border-radius: 15px;
//   overflow: hidden;

//   label,
//   input {
//     display: block;
//     height: 10vh;
//     width: 100%;
//     line-height: 5rem;
//     box-sizing: border-box;
//   }

//   label {
//     font-size: 15px;
//     font-weight: 900;
//   }

//   input {
//     font-size: 16px;
//   }

//   .wrapper__drop {
//     position: relative;

//     input {
//       padding: 0rem 2.5rem;
//       border: 0;
//       // box-shadow: 0 5px 20px 10px rgba(0, 0, 0, .1);
//       // border-radius: 50px;
//       text-transform: capitalize;
//       border: 1px solid #eee;

//       &:focus {
//         outline: none;
//         border: 2px solid #ccc;
//       }
//     }

//     .list__drop {
//       position: absolute;
//       top: 10vh;
//       left: 0;
//       height: 35vh;
//       width: 100%;
//       overflow-x: hidden;
//       overflow-y: scroll;
//       // border-radius: 0 0 50px 50px;
//       // box-shadow: 0 5px 20px 10px rgba(0, 0, 0, .1);
//       border: 1px solid #eee;

//       ul {
//         list-style: none;
//         height: 50%;
//         margin: 0;
//         padding: 0;

//         li {
//           height: 8vh;
//           margin: 0;
//           padding: 0;
//           display: flex;
//           align-items: center;

//           &:hover {
//             background-color: #eee;
//             cursor: pointer;
//           }

//           img {
//             width: 2.4rem;
//             height: 1.4rem;
//             margin: 0 2rem;
//           }

//           p {
//             font-size: 16px;
//             text-transform: capitalize;
//           }
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 700px) {
  .wrapper__drop {
    input {
      width: 90% !important;
    }
  }
}
.under__search {
  margin-top: 5%;
}
.wrapper__drop {
  input {
    background-color: $input-bg;
    border-radius: 15px;
    padding: 10px;
    color: $white;
    width: 300px;
  }
  .phone__search_event {
    width: 50vw;
  }
  .list__drop {
    ul {
      list-style: none;
      margin-top: 10px;
      max-width: 300px;
      li {
        height: 70px;
        img {
          border-radius: 50%;
          width: 50px;
          margin-right: 2rem;
          height: 50px;
          float: left;
        }
        p {
          padding-top: 10px;
        }
      }
    }
  }
}
