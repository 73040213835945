.card--events__finance__waves {
  width: 350px;
  background-color: $input-bg;
  border-radius: 15px;
  padding: 10px;
  margin: auto;

  hr {
    background-color: $white;
    margin: 6px 0;
  }
  h5 {
    color: $white;
  }
  .first-section__customer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .card-customers__user-details {
      .text {
        font-size: 16px;
        color: $white;
        font-weight: 700;
      }
    }
    .card-customers__date {
      background-image: linear-gradient(
        to bottom,
        rgba(168, 40, 74) 0%,
        rgb(218, 120, 75) 100%
      );
      color: $white;
      font-size: 16px;
      text-align: center;
      padding: 8px;
      border-radius: 100%;
      height: 40px;
      width: 40px;
      label {
        margin-bottom: 0.1rem !important;
      }
    }
  }
  .fees__type__waves {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      background-color: #75253e;
      border: 2px solid #892a4a;
      color: $white;
      font-size: 16px;
      padding: 4px;
      border-radius: 15px;
    }
  }

  .second-section__customer {
    color: $white;
    font-size: 16px;
  }
  .third-section__customer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .buttons__customer {
      background-color: $sidebar-bg;
      color: $white;
      border: none;
      border-radius: 15px;
      padding: 5px 16px;
    }
    .download {
      background-image: linear-gradient(
        to right,
        rgba(168, 40, 74),
        rgb(218, 120, 75)
      );
    }
    .wider {
      width: 100%;
    }
  }
  .btn__fees {
    margin: 10px 0px;
    button {
      border: none;
      width: 100%;
      background-image: linear-gradient(
        to right,
        rgba(168, 40, 74),
        rgb(218, 120, 75)
      );
      color: $white;
      border-radius: 15px;
      padding: 5px 16px;
    }
  }
}

@media screen and (max-width: 700px) {
  .title__waves {
    width: 40%;
  }
  .card--events__finance__waves {
    width: 240px;
    background-color: $input-bg;
    border-radius: 15px;
    padding: 10px;
    margin: auto;
    hr {
      background-color: $white;
      margin: 6px 0;
    }
    .first-section__customer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .card-customers__user-details {
        max-width: 60%;
        .text {
          font-size: 17px !important;
          margin-top: 7px;
        }
      }
      .card-customers__date {
        background-color: $sidebar-bg;
        color: $white;
        height: fit-content;
        font-size: 15px;
        text-align: center;
        padding: 8px;
        border-radius: 20px;
      }
    }
    .second-section__customer {
      color: $white;
      font-size: 12px;
    }
    .third-section__customer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .buttons__customer {
        background-color: $sidebar-bg;
        color: $white;
        border-radius: 15px;
        border: none;
        padding: 3px 6px;
      }
      .download {
        background-image: linear-gradient(
          to right,
          rgba(168, 40, 74),
          rgb(218, 120, 75)
        );
      }
    }
  }
  .fees__type__waves {
    button {
      border: 2px solid #892a4a !important;
      font-size: 15px !important;
      padding: 10px !important;
      margin-right: 2px;
    }
  }
}
