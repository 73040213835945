@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Sofia+Sans:wght@700&display=swap");

.container_book {
  width: 90%;
  padding: 10px;
  margin: 1rem auto;
}

.heading {
  font-size: 1.8rem;
  margin-bottom: 0.6rem;
  font-family: sans-serif;
}

.input {
  padding: 10px;
}

.card-title {
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
}

textarea {
  width: 100%;
  resize: none;
  padding: 8px;
  outline: none;
  border-radius: 4px;
  color: rgb(72, 66, 66);
}

.primary-btn {
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: blue;
}

.primary-btn:hover {
  background-color: rgb(36, 211, 27);
}

hr {
  margin: 10px auto;
}

.notes-heading {
  font-size: 1.4rem;
}
.notes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.delete-btn {
  border: none;
  font-size: 12px;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 2px;
  background-color: red;
}

.output-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.delete-btn:hover {
  background-color: #000;
}
.width__dynamic {
  width: 40%;
}
.noteCard {
  /* width: 22vw; */
  margin: 2vh 2vw;
  /* flex: 3 3; */
  padding: 8px;
  background-color: aliceblue;
  color: #000;
  border-radius: 6px;
  border: 1px solid black;
}

.noteCard img {
  height: 100px;
  width: 190px;
}
.booked-events {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 800px) {
  .noteCard img {
    width: 50vw;
  }
  .width__dynamic {
    width: 100%;
  }
}
.booked-events {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.responsive__input {
  width: 50vw;
}
.card-text {
  margin: 10px 0;
  text-align: center;
}

.droppable-container {
  background: #f0f0f0;
  padding: 10px;
  margin: 10px;
  width: 100%;
  max-width: 450px;
  min-height: 200px;
  box-sizing: border-box;
  border: 1px solid #ddd;
}

/* Draggable item */
.draggable-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 0 0 10px 0;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #000;
  div {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  :nth-child(2) {
    cursor: pointer;
  }
}

/* Task image */
.task-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  object-fit: cover;
}

.new_ordering {
  background: #f0f0f0;
  padding: 10px;
  width: 450px;
  min-height: 200px;
}
/* Responsive design */
@media (max-width: 768px) {
  .task-lists-container {
    flex-direction: column;
    align-items: center;
  }

  .droppable-container {
    width: 90%;
  }
  .new_ordering {
    width: max-content;
  }
}
