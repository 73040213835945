div.input-block {
  position: relative;
}

div.input-block input {
  font-weight: 500;
  font-size: 1.6rem;
  color: #495055;

  width: 350px;
  padding: 15px 15px;
  border-radius: 1rem;
  border: 2px solid #d9d9d9;
  outline: none;
}

div.input-block span.placeholder {
  position: absolute;
  margin: 17px 0;
  padding: 0 4px;
  font-family: Roboto, sans-serif;

  color: #6c757d;

  display: flex;
  align-items: center;

  font-size: 1.6rem;

  top: 0;
  left: 17px;

  transition: all 0.2s;
  transform-origin: 0% 0%;
  background: none;
  pointer-events: none;
}

div.input-block input:valid + span.placeholder,
div.input-block input:focus + span.placeholder {
  transform: scale(0.8) translateY(-30px);
  background: #fff;
}

div.input-block input:focus {
  color: #284b63;
  border-color: #284b63;
}

div.input-block input:focus + span.placeholder {
  color: #284b63;
}
