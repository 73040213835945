$pink: rgb(253, 0, 118);
.loader {
  position: relative;
  top: 0;
  bottom: 0;

  left: 0;
  padding-bottom: 40px;
  padding-top: 40px;
  right: 0;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after {
  position: absolute;
  top: 0;
  content: "";
}

.jimu-primary-loading:before {
  left: -19.992px;
}

.jimu-primary-loading:after {
  left: 19.992px;
  -webkit-animation-delay: 0.32s !important;
  animation-delay: 0.32s !important;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after,
.jimu-primary-loading {
  background: $pink;
  -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  width: 13.6px;
  height: 32px;
  border-radius: 10px;
}

.jimu-primary-loading {
  text-indent: -9999em;
  margin: auto;
  position: absolute;
  right: calc(50% - 6.8px);
  top: calc(50% - 16px);
  -webkit-animation-delay: 0.16s !important;
  animation-delay: 0.16s !important;
}

@-webkit-keyframes loading-keys-app-loading {
  0%,
  80%,
  100% {
    opacity: 0.75;
    box-shadow: 0 0 $pink;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px $pink;
    height: 40px;
  }
}

@keyframes loading-keys-app-loading {
  0%,
  80%,
  100% {
    opacity: 0.75;
    box-shadow: 0 0 $pink;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px $pink;
    height: 40px;
  }
}

.loader1 {
  position: relative;
  // top: 0;
  // bottom: 0;
  margin-left: 14px;
  margin-top: 18px;
  // left: 0;
  // // padding-bottom: 40px;
  // // padding-top: 40px;
  // right: 0;
}

.jimu-primary-loading1:before,
.jimu-primary-loading1:after {
  position: absolute;
  top: 0;
  content: "";
}

.jimu-primary-loading1:before {
  left: -7.992px;
}

.jimu-primary-loading1:after {
  left: 7.992px;
  -webkit-animation-delay: 0.32s !important;
  animation-delay: 0.32s !important;
}

.jimu-primary-loading1:before,
.jimu-primary-loading1:after,
.jimu-primary-loading1 {
  background: $pink;
  -webkit-animation: loading-keys-app-loading1 0.8s infinite ease-in-out;
  animation: loading-keys-app-loading1 0.8s infinite ease-in-out;
  width: 3px;
  height: 7px;
  border-radius: 10px;
}

.jimu-primary-loading1 {
  text-indent: -9999em;
  margin: auto;
  position: absolute;
  right: calc(50% - 6.8px);
  top: calc(50% - 16px);
  -webkit-animation-delay: 0.16s !important;
  animation-delay: 0.16s !important;
}

@-webkit-keyframes loading-keys-app-loading1 {
  0%,
  80%,
  100% {
    opacity: 0.75;
    box-shadow: 0 0 $pink;
    height: 7px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px $pink;
    height: 11px;
  }
}

@keyframes loading-keys-app-loading1 {
  0%,
  80%,
  100% {
    opacity: 0.75;
    box-shadow: 0 0 $pink;
    height: 7px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px $pink;
    height: 11px;
  }
}
