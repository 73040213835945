.table-utilities {
  display: flex;
  justify-content: space-between;
  .left-title {
    color: #ffffff;
  }
  .right-utilities {
    display: flex;
    gap: 10px;
    button {
      background-color: #131720;
      border: none;
      color: #d6764b;
      padding: 5px 10px;
      -webkit-box-shadow: 0px 0px 19px 0px rgba(25, 28, 36, 1);
      -moz-box-shadow: 0px 0px 19px 0px rgba(25, 28, 36, 1);
      box-shadow: 0px 0px 19px 0px rgba(25, 28, 36, 1);
    }
    .refresh {
      border-radius: 20px;
      span {
        color: white;
      }
    }
    .filter {
      border-radius: 50%;
    }
    .search-filter {
      .search {
        width: 100%;
        position: relative;
        display: flex;
      }

      .searchTerm {
        width: 100%;
        border: none;
        border-left: none;
        padding: 5px;
        border-radius: 0 15px 15px 0;
        outline: none;
        color: #ffffff;
        line-height: normal;
        background-color: #131720;
      }
      .searchButton {
        width: 40px;
        height: 36px;
        border: none;
        background: #131720;
        text-align: center;
        border-right: none;
        border-radius: 15px 0 0 15px;
        cursor: pointer;
        font-size: 20px;
        display: flex;
        align-items: center;
      }
      .custom-loader {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 4px solid;
        border-color: #131720;
        border-right-color: #d6764b;
        animation: s2 0.5s infinite linear;
      }
      @keyframes s2 {
        to {
          transform: rotate(1turn);
        }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .table-utilities {
    .left-title {
      display: flex;
      // flex: 0.8;
      align-items: center;
      h3 {
        font-size: 12px;
      }
    }
    .right-utilities {
      .refresh {
        font-size: 12px;
      }
    }
  }

  .modal-content {
    width: fit-content !important;
    .content {
      .react-datepicker__input-container input {
        width: 100px;
      }
    }
  }
  .search-filter {
    display: flex;
    justify-content: flex-end;

    .search {
      width: 80% !important;
    }
  }
}
.modal {
  display: grid; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  padding: $card-padding-y $card-padding-x;
}

/* Modal Content */
.modal-content {
  background-color: #131720;
  margin: auto;
  padding: 20px;
  border: none;
  width: calc(74% - #{$sidebar-width-lg});
  border-radius: 20px;
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 1rem !important;
    .title {
      h3 {
        color: #ffffff;
      }
    }

    .close-mod {
      font-size: 28px;
      cursor: pointer;
      opacity: 1 !important;
      color: #ffffff;
      background-image: linear-gradient(
        to right,
        rgba(168, 40, 74),
        rgb(218, 120, 75)
      );
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 200;
      padding: 0px 10px !important;
      line-height: normal;
      margin-bottom: 10px;
    }
  }
  .content {
    .react-datepicker__input-container input {
      width: 120px;
    }
    .widget {
      display: flex;
      flex-direction: column;
      h4 {
        text-align: left;
        margin: 20px 0 20px 10px;
        color: #ffffff;
        font-size: 14px;
      }

      .select {
        cursor: pointer;
        display: inline-block;
        position: relative;
        font-size: 16px;
        color: #fff;
        width: 100%;
        height: 40px;
        border: none;
      }
      select {
        display: none;
      }
      .wrap h1 {
        color: #181b33;
        background: #232323;
        font-size: 47px;
        text-align: center;
        font-family: monospace;
      }
      .custom-select {
        color: #ffffff;
        padding: 8px 15px;
        -moz-transition: all 0.2s ease-in;
        -o-transition: all 0.2s ease-in;
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
        width: 40%;
        border-radius: 15px;
        height: 35px;
        background-color: #0f1015;
      }
      .custom-select:after {
        content: "";
        width: 0;
        height: 0;
        transform: rotateZ(0deg);
        transition: all 0.5s;
        transform-origin: 50% 25%;
      }

      .custom-select:active:after,
      .custom-select.active:after {
        transform: rotateZ(180deg);
        transition: all 0.5s;
        transform-origin: 50% 25%;
      }
      .custom-select:focus {
        outline: 2px;
      }
      .select-options {
        max-height: 200px;
        overflow-y: auto;
        display: block;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        z-index: 999;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #0f1015;
        border-radius: 0px 0px 5px 5px;
      }
      .select-options li {
        color: #fff;
        margin: 0;
        padding: 12px 0;
        -moz-transition: all 0.15s ease-in;
        -o-transition: all 0.15s ease-in;
        -webkit-transition: all 0.15s ease-in;
        transition: all 0.15s ease-in;
        padding-left: 15px;
      }
      .select-options li:hover {
        color: #424242;
        background: #fff;
      }
      .select-options li[rel="hide"] {
        display: none;
      }
      ::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5;
        border-radius: 0 0 5px 0;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #9e9e9e;
      }
    }
    .buttons-widget {
      display: flex;
      align-items: flex-start;
      margin: 20px 0;
    }
    .fees-button {
      background-color: #0f1015;
      color: white;
      line-height: normal;
      padding: 5px 10px;
      border: none;
      border-radius: 15px;
      margin-right: 5px;
    }
    .selected {
      border: 1px solid #d6764b;
    }
    .apply {
      display: flex;
      justify-content: flex-end;
      button {
        padding: 8px 50px;
        border-radius: 20px;
      }
    }
  }
}
