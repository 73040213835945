.pagination {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.pagination a {
  color: white;
  background-image: linear-gradient(
    to bottom,
    rgba(168, 40, 74) 0%,
    rgb(218, 120, 75) 100%
  );
  padding: 5px 10px;
  margin: 0 3px;
  border-radius: 50% !important;
  border: none;
  cursor: pointer;
  font-weight: 800;
}
.pagination a:focus {
  box-shadow: none;
}

.pagination a:hover {
  color: white !important;
}
.pagination .disabled {
  cursor: not-allowed !important;
}
.pagination .disabled a {
  background-image: none;
  color: white !important;
  background-color: rgb(145, 145, 145) !important;
}
// .pagination .active {
//   background-image: linear-gradient(rgba(168, 40, 74), rgb(218, 120, 75));
// }
.page-item.active .page-link {
  background-image: linear-gradient(rgba(168, 40, 74), rgb(218, 120, 75));
  border-color: transparent;
}
.page-item.active {
  display: none !important;
}
