:root {
  --squish-animation-time: 500ms;
  --progress-animation-time: 10000ms;
  --circle-animation-time: 300ms;
  --checkmark-animation-time: 300ms;
  --btn_verified-width: 125px;
  --btn_verified-height: 40px;
}

.btn_verified {
  position: relative;
  background-image: linear-gradient(
    to right,
    rgba(168, 40, 74),
    rgb(218, 120, 75)
  );
  color: white;
  border: none;
  border-radius: 15px;
  width: 100%;
  height: var(--btn_verified-height);
  font-weight: bold;
  cursor: pointer;
  padding: 0;
}

.btn_verified.animating {
  background-color: transparent;
  background: transparent;
  color: transparent;
  user-select: none;
  cursor: default;
  animation: hide 0ms
    calc(var(--squish-animation-time) + var(--progress-animation-time)) forwards;
}

.btn_verified::before {
  content: "";
  display: none;
  position: absolute;
  background-color: #2b2d2f;
  inset: 0;
  width: 98%;
  margin: auto;
  border-radius: 0.125em;
  animation: squish var(--squish-animation-time)
    cubic-bezier(0.26, 0.6, 0.46, 1.7);
  animation-fill-mode: forwards;
}

.btn_verified::after {
  content: "";
  display: none;
  position: absolute;
  background-color: #aaa;
  left: 45%;
  right: 51%;
  top: 45%;
  bottom: 45%;
  border-radius: 0.25em;
  animation: progress var(--progress-animation-time)
    var(--squish-animation-time);
  animation-fill-mode: forwards;
}

.btn_verified.animating::before,
.btn_verified.animating::after {
  display: block;
}
.btn_verified.animating.done + .checkmark-container {
  animation: circle var(--circle-animation-time)
    calc(var(--squish-animation-time) + var(--progress-animation-time)) forwards
    cubic-bezier(0.26, 0.6, 0.46, 1.7);
}

.btn_verified.animating.fail + .checkmark-container {
  animation: failed var(--circle-animation-time)
    calc(var(--squish-animation-time) + var(--progress-animation-time)) forwards
    cubic-bezier(0.26, 0.6, 0.46, 1.7);
}
.btn_verified.animating + .checkmark-container {
  background-color: #2b2d2f;
  border-radius: 0.25em;
  width: 0;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkmark-container {
  margin: auto;
}
.btn_verified.animating + .checkmark-container .checkmark-svg {
  stroke: white;
  width: 25px;
  stroke-dashoffset: 40.84104919433594;
  stroke-dasharray: 40.84104919433594;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3px;
  animation: checkmark var(--circle-animation-time)
    calc(
      var(--squish-animation-time) + var(--progress-animation-time) +
        var(--circle-animation-time)
    )
    forwards;
}

@keyframes squish {
  100% {
    left: -25%;
    right: -25%;
    top: 45%;
    bottom: 45%;
    border-radius: 0.25em;
  }
}

@keyframes progress {
  100% {
    left: 0%;
    right: 0%;
  }
}

@keyframes hide {
  100% {
    width: 0;
    height: 0;
  }
}

@keyframes circle {
  0% {
    width: calc(var(--btn_verified-width) * 1.5);
    height: calc(var(--btn_verified-height) * 0.1);
  }
  100% {
    background-color: #71dfbe;
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }
}
@keyframes failed {
  0% {
    width: calc(var(--btn_verified-width) * 1.5);
    height: calc(var(--btn_verified-height) * 0.1);
  }
  100% {
    background-color: red;
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }
}

@keyframes checkmark {
  100% {
    stroke-dashoffset: 0;
  }
}
