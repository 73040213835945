.show-btn {
  background: #fff;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  color: #3498db;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.1s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container-fees {
  transform-origin: center;
}
.show-btn:active {
  scale: 0.9;
}
.container-fees {
  scale: 0;
  opacity: 0;
  z-index: -1;
  background: #fff;
  width: 410px;
  padding: 30px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.container-fees {
  scale: 1;
  opacity: 1;
  z-index: 11;
}
.container-fees .close-btn {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 18px;
  cursor: pointer;
}
.container-fees .close-btn:hover {
  color: #3498db;
}
.container-fees .text {
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}
.container-fees form {
  margin-top: -20px;
  transition: all 0.3s ease;
}
.container-fees form .data {
  height: 45px;
  width: 100%;
  margin: 40px 0;
}
.fees__form .data label {
  font-size: 18px;
}
.fees__form .data input {
  height: 100%;
  width: 100%;
  padding-left: 10px;
  font-size: 17px;
  border: 1px solid silver;
}
.fees__form.data input:focus {
  border-color: #3498db;
  border-bottom-width: 2px;
}
.fees__form .forgot-pass {
  margin-top: -8px;
}
.fees__form .forgot-pass a {
  color: #3498db;
  text-decoration: none;
}
.fees__form .forgot-pass a:hover {
  text-decoration: underline;
}
.fees__form .btn {
  margin: 30px 0;
  height: 45px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.fees__form .btn .inner {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  z-index: -1;
  transition: all 0.4s;
}
.fees__form .btn:hover .inner {
  left: 0;
}
.fees__form .btn button {
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  color: #fff;
  background: #11131e;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
}
.fees__form .signup-link {
  text-align: center;
}
.fees__form .signup-link a {
  color: #3498db;
  text-decoration: none;
}
.fees__form .signup-link a:hover {
  text-decoration: underline;
}
