.notification__popup {
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: max-content;
  padding: 20px 15px;
  border-radius: 4px;
  background-color: #141619;
  color: #f6f5f9;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  animation: fade-in 4s linear forwards;
}
.notification__progress {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: calc(100% - 10px);
  height: 3px;
  transform: scaleX(0);
  transform-origin: left;
  background-image: linear-gradient(
    to right,
    rgb(218, 120, 75),
    rgba(168, 40, 74)
  );
  border-radius: inherit;
  animation: load 3s 0.25s linear forwards;
}
@keyframes fade-in {
  5% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes load {
  to {
    transform: scaleX(1);
  }
}

@media screen and (min-width: 980px) {
  // .notification__popup {
  //   // left: 300px !important;
  // }
}
